// src/hooks/useFonts.js

import { useEffect } from 'react';
import transformFonts from '../Utils/transformFonts';

const useFonts = (restaurantData) => {
  useEffect(() => {
    if (!restaurantData) return;

    // Transform and extract fonts
    let { fonts } = restaurantData;
    fonts = transformFonts(fonts);

    const { titleFont, subtitleFont, buttonFont, labelFont } = fonts;

    // List of native fonts
    const nativeFonts = ['Arial', 'Helvetica', 'Times New Roman', 'Georgia', 'Courier New', 'Verdana'];

    // All fonts used in your application
    const fontsUsed = [titleFont.font, subtitleFont.font, buttonFont.font, labelFont.font];

    // Filter out native fonts to get non-native fonts
    const nonNativeFonts = fontsUsed.filter((font) => !nativeFonts.includes(font));

    // Remove duplicates
    const uniqueNonNativeFonts = [...new Set(nonNativeFonts)];

    if (uniqueNonNativeFonts.length > 0) {
      const fontKeys = ['titleFont', 'subtitleFont', 'labelFont', 'buttonFont'];

      const fontsToImport = uniqueNonNativeFonts.map((font) => {
        const fontWeights = fontKeys
          .filter((key) => fonts[key].font === font)
          .map((key) => fonts[key].weight);

        const uniqueWeights = [...new Set(fontWeights)];
        return `family=${encodeURIComponent(font)}:wght@${uniqueWeights.join(';')}`;
      });

      const googleFontsUrl = `https://fonts.googleapis.com/css2?${fontsToImport.join('&')}&display=swap`;

      // Create or update the link element
      let linkElement = document.getElementById('dynamic-fonts-import');
      if (!linkElement) {
        linkElement = document.createElement('link');
        linkElement.id = 'dynamic-fonts-import';
        linkElement.rel = 'stylesheet';
        document.head.appendChild(linkElement);
      }
      linkElement.href = googleFontsUrl;
    }
  }, [restaurantData]);
};

export default useFonts;
