export function darkenHexColor(hex, factor = 0.2) {
    // Remove the '#' if present
    let cleanHex = hex.replace('#', '');

    // Parse the hex color into RGB components
    const r = parseInt(cleanHex.slice(0, 2), 16);
    const g = parseInt(cleanHex.slice(2, 4), 16);
    const b = parseInt(cleanHex.slice(4, 6), 16);

    // Apply the darkening factor
    const newR = Math.max(0, Math.floor(r * (1 - factor)));
    const newG = Math.max(0, Math.floor(g * (1 - factor)));
    const newB = Math.max(0, Math.floor(b * (1 - factor)));

    // Convert back to hex and return
    const darkenedHex = '#' +
      newR.toString(16).padStart(2, '0') +
      newG.toString(16).padStart(2, '0') +
      newB.toString(16).padStart(2, '0');

    return darkenedHex;
  }

  export function isColorTooBright(hex) {
    let cleanHex = hex.replace('#', '');
    const r = parseInt(cleanHex.slice(0, 2), 16);
    const g = parseInt(cleanHex.slice(2, 4), 16);
    const b = parseInt(cleanHex.slice(4, 6), 16);

    // Calculate the perceived brightness
    // Formula: (0.299 * R + 0.587 * G + 0.114 * B)
    const brightness = (0.299 * r) + (0.587 * g) + (0.114 * b);
    return brightness > 200; // Arbitrary threshold for brightness
  }