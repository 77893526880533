// src/Pages/ReservationForm/index.js

import React, { useState, useEffect } from 'react';
import StepOne from '../../Steps/StepOne';
import StepTwo from '../../Steps/StepTwo';
import SuccessMessage from '../../Steps/StepThree';
import './css/form.css';

const ReservationForm = ({ title, settings, generalSettings, fields, timeblocks, menu, mededeling, restaurantData }) => {
  const initialFormData = {};
  fields.forEach((field) => {
    initialFormData[field.id] = '';
  });

  const [formData, setFormData] = useState(initialFormData);
  const [currentStep, setCurrentStep] = useState(1);
  const [expandedField, setExpandedField] = useState(null);

  const handleChange = (e) => {
    const { name, value, type } = e.target;

    if (type === 'number' && !/^\d*$/.test(value)) {
      return;
    }

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    if (formData.date) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        time: '',
      }));
    }
  }, [formData.date]);

  const resetFormDataFields = (fieldsToReset) => {
    setFormData((prevFormData) => {
      const newFormData = { ...prevFormData };
      fieldsToReset.forEach((field) => {
        newFormData[field] = '';
      });
      return newFormData;
    });
  };

  const goToStep = (step, field) => {
    if (step === 1) {
      if (field === 'guest') {
        resetFormDataFields(['date', 'time']);
      } else if (field === 'date') {
        resetFormDataFields(['time']);
      }
    }
    setCurrentStep(step);
    setExpandedField(field || null);
  };

  return (
    <div className="reservation-form">
      {currentStep === 1 && (
        <StepOne
          title={title}
          timeblocks={timeblocks}
          generalNotification={settings?.generalNotification}
          formData={formData}
          handleChange={handleChange}
          resetFormDataFields={resetFormDataFields}
          setCurrentStep={goToStep}
          fields={fields}
          expandedField={expandedField}
          generalSettings={generalSettings} // Pass generalSettings to StepOne
          mededeling={mededeling} // Pass mededeling to StepOne
          restaurantData={restaurantData} // Pass restaurantData to StepOne
        />
      )}

      {currentStep === 2 && (
        <StepTwo
          title={title}
          formData={formData}
          handleChange={handleChange}
          setCurrentStep={goToStep}
          fields={fields}
          menu={menu}
        />
      )}

      {currentStep === 3 && <SuccessMessage />}
    </div>
  );
};

export default ReservationForm;
