// src/Utils/computeAlignmentValues.js

const computeAlignmentValues = (alignment) => {
	let alignmentBottomValue;
	let alignmentTopValue;
	let alignmentMarginValue;
  
	if (alignment === 'fullScreenColor') {
	  alignmentBottomValue = '0';
	  alignmentTopValue = '-30px';
	  alignmentMarginValue = '30px';
	} else if (alignment === 'fullScreenPicture') {
	  alignmentBottomValue = '0';
	  alignmentTopValue = '100vh';
	  alignmentMarginValue = '-100vh';
	} else if (alignment === 'halfColorPicture' || alignment === '50/50') {
	  alignmentBottomValue = 'calc(100vh - 250px)';
	  alignmentTopValue = '250px';
	  alignmentMarginValue = '-200px';
	}
  
	return {
	  alignmentBottomValue,
	  alignmentTopValue,
	  alignmentMarginValue,
	};
  };
  
  export default computeAlignmentValues;
  