// src/components/NewReservation/ReservationModal.jsx

import React from 'react';
import reserveIcon from '../../../Assets/logos/logo-white.webp'; // Ensure this path is correct
import './css/popup.css';

const ReservationModal = ({ isModalOpen, isClosing, openModal, closeModal, formContent }) => {
  return (
    <>
      <button className="open-modal-button" onClick={openModal}>
        <span className="button-content">
          <span className="button-text">Boek een Tafel</span>
          <div className="separator"></div>
          <img src={reserveIcon} alt="Reserve Icon" className="button-icon" />
        </span>
      </button>
      {(isModalOpen || isClosing) && (
        <>
          <div
            className={`modal-overlay ${isClosing ? 'hide' : 'show'}`}
            onClick={closeModal}
          ></div>
          <div className={`modal ${isClosing ? 'hide' : 'show'}`}>
            <div className="modal-content">
              <button className="close-modal-button" onClick={closeModal}>
                &times;
              </button>
              {formContent}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ReservationModal;
