// src/components/NewReservation/Title.jsx

import React from 'react';

const Title = ({ title, subtitle }) => {
  return (
    <>
      <h2 className='title default-text-color'>{title}</h2>
      <h3 className="subtitle default-text-color">{subtitle}</h3>
    </>
  );
};

export default Title;
