import React from 'react';
import { FaCalendarPlus } from 'react-icons/fa';
import './css/buttons.css';

const AddToCalendarButton = ({ summaryData }) => {
  const handleAddToCalendar = () => {
    console.log('handleAddToCalendar invoked');

    if (!summaryData) {
      console.error('No summaryData available.');
      return;
    }

    const restaurantName = localStorage.getItem('restaurant') || 'Restaurant';
    console.log('Restaurant Name:', restaurantName);

    const { date, time } = summaryData;
    console.log('Summary Data - Date:', date);
    console.log('Summary Data - time:', time);

    let datePart;
    if (date) {
      const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
      if (!dateRegex.test(date)) {
        console.error('Invalid date format in summaryData.date:', date);
        return;
      }
      datePart = date;
    } else if (window.selectedDate instanceof Date && !isNaN(window.selectedDate)) {
      const selectedDate = window.selectedDate;
      const year = selectedDate.getFullYear();
      const month = String(selectedDate.getMonth() + 1).padStart(2, '0');
      const day = String(selectedDate.getDate()).padStart(2, '0');
      datePart = `${year}-${month}-${day}`;
      console.log('Using window.selectedDate for datePart:', datePart);
    } else {
      console.error('No valid date available.');
      return;
    }

    const timeRegex = /^\d{2}:\d{2}$/;
    if (!timeRegex.test(time)) {
      console.error('Invalid time format:', time);
      return;
    }

    const dateTimeString = `${datePart}T${time}:00`;
    console.log('Constructed Date-Time String:', dateTimeString);

    const [hours, minutes] = time.split(':').map(Number);
    const [year, month, day] = datePart.split('-').map(Number);

    const startDateTime = new Date(year, month - 1, day, hours, minutes, 0);
    console.log('startDateTime:', startDateTime);

    if (isNaN(startDateTime.getTime())) {
      console.error('Invalid startDateTime:', dateTimeString);
      return;
    }

    const endDateTime = new Date(startDateTime.getTime() + 2 * 60 * 60 * 1000);
    console.log('endDateTime:', endDateTime);

    const formatDate = (date) => {
      if (!(date instanceof Date) || isNaN(date.getTime())) {
        console.error('Invalid Date object passed to formatDate:', date);
        return '';
      }
      const pad = (num) => String(num).padStart(2, '0');
      const year = date.getFullYear();
      const month = pad(date.getMonth() + 1);
      const day = pad(date.getDate());
      const hours = pad(date.getHours());
      const minutes = pad(date.getMinutes());
      const seconds = pad(date.getSeconds());

      return `${year}${month}${day}T${hours}${minutes}${seconds}`;
    };

    const start = formatDate(startDateTime);
    const end = formatDate(endDateTime);

    if (!start || !end) {
      console.error('Formatted start or end date is invalid.');
      return;
    }

    const description = `Restaurant reservering bij ${restaurantName}`;
    const location = restaurantName;
    const subject = `Reservering bij ${restaurantName}`;

    const icsContent = `BEGIN:VCALENDAR
VERSION:2.0
BEGIN:VEVENT
DTSTART:${start}
DTEND:${end}
SUMMARY:${subject}
DESCRIPTION:${description}
LOCATION:${location}
END:VEVENT
END:VCALENDAR`;

    console.log('ICS Content:', icsContent);

    try {
      const blob = new Blob([icsContent], { type: 'text/calendar;charset=utf-8' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'reservation.ics';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
      console.log('ICS file downloaded successfully.');
    } catch (error) {
      console.error('Error creating or downloading ICS file:', error);
    }
  };

  return (
    <button className="action-button add-to-calendar-button" onClick={handleAddToCalendar}>
      <FaCalendarPlus className="button-icon" />
      Agenda
    </button>
  );
};

export default AddToCalendarButton;
