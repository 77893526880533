import React from 'react';
import { FaShareAlt } from 'react-icons/fa';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import './css/buttons.css'

const ShareButton = ({ summaryRef }) => {
  const handleShare = async () => {
    if (summaryRef.current) {
      const canvas = await html2canvas(summaryRef.current);
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF();
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      const pdfBlob = pdf.output('blob');

      if (navigator.share && navigator.canShare({ files: [new File([], '')] })) {
        const file = new File([pdfBlob], 'reservation.pdf', { type: 'application/pdf' });
        try {
          await navigator.share({
            title: 'Mijn Reservering',
            text: 'Bekijk mijn reservering.',
            files: [file],
          });
        } catch (error) {
          console.error('Error sharing:', error);
        }
      } else {
        pdf.save('reservation.pdf');
      }
    }
  };

  return (
    <button className="action-button share-button" onClick={handleShare}>
      <FaShareAlt className="button-icon" />
      Deel
    </button>
  );
};

export default ShareButton;
