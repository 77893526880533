// src/components/Notifications/MededelingNotification.jsx

import React from 'react';
import './css/mededelingNotification.css'; // Create corresponding CSS

const MededelingNotification = ({ message }) => {
  return (
    <div className="mededeling-notification">
      <p>{message}</p>
    </div>
  );
};

export default MededelingNotification;
