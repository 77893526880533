// src/components/NewReservation/formConfig.js


export const title = 'Reserveer een Tafel';
export const notification = 'Uw reservering is succesvol geplaatst!';
export const theme = {
  id: 1,
  title: 'Oceaan Bries',
  color: 'pink',
  buttonColor: 'rgb(226, 139, 153)',
};

export const font = "Poppins";

export const fields = [
  {
    id: 'naam',
    type: 'input',
    label: 'Naam',
    placeholder: 'Uw naam',
    required: true,
  },
  {
    id: 'date',
    type: 'date',
    label: 'date',
    required: true,
  },
  {
    id: 'time',
    type: 'time',
    label: 'Tijd',
    required: true,
  },
  {
    id: 'guests',
    type: 'number',
    label: 'Aantal Personen',
    placeholder: 'Bijvoorbeeld: 4',
    required: true,
    min: '1',
  },
  {
    id: 'extraInfo',
    type: 'textarea',
    label: 'Opmerkingen',
    placeholder: 'Eventuele opmerkingen of speciale verzoeken',
    required: false,
  },
];
