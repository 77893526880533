// src/Hooks/useFetchRequiredFields.js

import { useState, useEffect } from 'react';

/**
 * Custom hook to fetch required restaurant fields.
 *
 * @returns {Object} - { requiredFields, loadingFields }
 */
const useFetchRequiredFields = () => {
  const [fields, setRequiredFields] = useState([]);
  const [loadingFields, setLoadingFields] = useState(true);

  useEffect(() => {
    const fetchRequiredFields = async () => {
      try {

        const defaultRequiredFields = [
          { id: 'firstName', label: 'Voornaam', type: 'input', required: true },
          { id: 'lastName', label: 'Achternaam', type: 'input', required: true },
          { id: 'email', label: 'E-mailadres', type: 'email', required: true },
          { id: 'phone', label: 'Telefoonnummer', type: 'phone', required: true },
          { id: 'extraInfo', label: 'Extra informatie / Allergieën', type: 'textarea', required: false },
        ];

        setRequiredFields(defaultRequiredFields);
      } catch (error) {
        console.error('Error fetching required fields:', error);
        setRequiredFields([]);
      } finally {
        setLoadingFields(false);
      }
    };

    fetchRequiredFields();
  }, []);

  return { fields, loadingFields };
};

export default useFetchRequiredFields;
