// Utility function to convert date to Dutch day of week
export const getDutchDayOfWeek = (dateString) => {
	const date = new Date(dateString);
	const daysDutch = [
	  'zondag',
	  'maandag',
	  'dinsdag',
	  'woensdag',
	  'donderdag',
	  'vrijdag',
	  'zaterdag',
	];
	return daysDutch[date.getDay()];
  };