import React from 'react';
import { FaExclamationTriangle } from 'react-icons/fa';
import './css/maxGuestsMessage.css';

const MaxGuestMessage = ({ maxGuests, noticePhoneNumber, type }) => {
  let message = '';

  if (type === 'call') {
    message = `Het aantal gasten is hoger dan het maximum van ${maxGuests} voor online reservaties. Neem alstublieft telefonisch contact met ons op${noticePhoneNumber ? ` via ${noticePhoneNumber}` : ''} om uw reservatie te maken.`;
  } else if (type === 'limit') {
    message = `U kunt maximaal ${maxGuests} gasten online reserveren.`;
  }

  return (
    <div className="max-guest-message">
      <FaExclamationTriangle className="warning-icon" />
      <p>{message}</p>
    </div>
  );
};

export default MaxGuestMessage;
