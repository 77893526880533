// src/utils/transformFonts.js

const transformFonts = (fonts) => {
	const defaultFonts = {
	  titleFont: { font: 'Poppins', weight: '400' },
	  subtitleFont: { font: 'Poppins', weight: '400' },
	  labelFont: { font: 'Poppins', weight: '400' },
	  buttonFont: { font: 'Poppins', weight: '400' },
	};
  
	const fontKeys = ['titleFont', 'subtitleFont', 'labelFont', 'buttonFont'];
	const transformedFonts = {};
  
	for (const key of fontKeys) {
	  const fontValue = fonts[key];
	  if (typeof fontValue === 'string') {
		transformedFonts[key] = { font: fontValue, weight: '400' };
	  } else if (fontValue && typeof fontValue === 'object') {
		transformedFonts[key] = fontValue;
	  } else {
		transformedFonts[key] = defaultFonts[key];
	  }
	}
  
	return transformedFonts;
  };
  
  export default transformFonts;
  