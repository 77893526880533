// Define default settings
export const defaultSettings = {
	pageTitle: 'Reserveer Nu',
	generalNotification: '',
	alignment: 'fullScreenPicture', // Default alignment
	backgroundBlur: 'blurBackground', // Default background blur
  };
  
  // Define default fonts
  export const defaultFonts = {
	titleFont: { font: 'Poppins', weight: '400' },
	subtitleFont: { font: 'Poppins', weight: '400' },
	labelFont: { font: 'Poppins', weight: '400' },
	buttonFont: { font: 'Poppins', weight: '400' },
  };
  
  // Define default appearance data
  export const defaultAppearanceData = {
	backgroundType: 'solid',
	backgroundColor: '#000',
	gradientStartColor: '#FFFFFF',
	gradientEndColor: '#000000',
	animationType: 'none',
	widgetBackgroundColor: '#000000',
	widgetTextColor: '#FFFFFF',
	textColor: '#000000',
	containerColor: '#FFFFFF',
	buttonColor: '#000000',
	buttonTextColor: '#FFFFFF',
  };
  
  // Define default theme
  export const defaultTheme = {
	id: 10, // Default theme ID
	title: 'Herfstbladeren', // Default theme title
	color: '#D35400', // Default theme color
	image: '/static/media/default-theme.webp', // Default theme image
  };

  