import React from 'react';

const ReservationStyles = ({ fonts, formSectionBackgroundStyle }) => {
  const { labelFont, titleFont, subtitleFont, buttonFont } = fonts;

  return (
    <style>{`
      .new-reservation-page {
        font-family: '${labelFont?.font}', sans-serif !important;
        font-weight: ${labelFont?.weight} !important;
      }

      input {
        font-family: '${labelFont?.font}', sans-serif !important;
        font-weight: ${labelFont?.weight} !important;
      }

      .title {
        font-family: '${titleFont?.font}', sans-serif !important;
        font-weight: ${titleFont?.weight} !important;
      }
      .subtitle {
        font-family: '${subtitleFont?.font}', sans-serif !important;
        font-weight: ${subtitleFont?.weight} !important;
      }
      .submit-button,
      .action-button {
        font-family: '${buttonFont?.font}', sans-serif !important;
        font-weight: ${buttonFont?.weight} !important;
      }
      ${formSectionBackgroundStyle}
    `}</style>
  );
};

export default ReservationStyles;
