// src/Utils/formatDateToDutch.js

/**
 * Converts a date string from 'YYYY-MM-DD' format to 'D MMMM YYYY' in Dutch.
 * Example: '2024-10-07' => '7 oktober 2024'
 *
 * @param {string} dateString - The date string in 'YYYY-MM-DD' format.
 * @returns {string} - The formatted date string in Dutch.
 */
const formatDateToDutch = (dateString) => {
	if (!dateString) return '';
  
	// Replace hyphens with slashes to ensure correct parsing across browsers
	const normalizedDateString = dateString.replace(/-/g, '/');
	const date = new Date(normalizedDateString);
  
	if (isNaN(date)) return dateString; // Return original string if invalid date
  
	return new Intl.DateTimeFormat('nl-NL', {
	  day: 'numeric',
	  month: 'long',
	  year: 'numeric',
	}).format(date);
  };
  
  export default formatDateToDutch;
  