// PrintButton.jsx
import React from 'react';
import { FaPrint } from 'react-icons/fa';
import './css/buttons.css';

const PrintButton = ({ summaryRef }) => {
  const handlePrint = () => {
    if (summaryRef.current) {
      const printContent = summaryRef.current.innerHTML;
      const printWindow = window.open('', '', 'height=600,width=800');
      printWindow.document.write(`
        <html>
          <head>
            <title>Overzicht Reservatie</title>
            <style>
              /* Reset default margins and paddings */
              * {
                margin: 0;
                padding: 0;
                box-sizing: border-box;
              }
              body {
                font-family: Poppins, sans-serif;
                background-color: white;
                height: 100vh;
                display: flex;
                justify-content: center;
                align-items: center;
              }
              .reservation-summary {
                padding: 20px;
                border: 1px solid #ccc;
                border-radius: 8px;
                max-width: 600px;
                width: 100%;
                background-color: white;
              }
              .title {
                text-align: center;
                margin-bottom: 20px;
              }
              ul {
                list-style-type: none;
              }
              li {
                margin-bottom: 10px;
                font-size: 16px;
              }
              strong {
                display: inline-block;
                width: 150px;
              }
            </style>
          </head>
          <body>
            <div class="reservation-summary">
              ${printContent}
            </div>
          </body>
        </html>
      `);

      // Close the document to ensure all resources are loaded
      printWindow.document.close();

      // Wait for the content to load before printing
      printWindow.onload = () => {
        printWindow.focus();
        printWindow.print();
        printWindow.close();
      };
    } else {
      console.error('Reservation summary not found.');
      alert('Geen reserveringsgegevens gevonden om te printen.');
    }
  };

  return (
	<button className="action-button print-button" onClick={handlePrint}>
		<FaPrint className="button-icon" />
		Print
	</button>
  );
};

export default PrintButton;
