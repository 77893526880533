// src/components/Modal/Fields/GuestSelector.jsx

import React, { useState, useEffect, useRef } from 'react';
import './css/guestNumberSelector.css';
import './css/mobile.css'

const GuestNumberSelector = ({
  setGuests,
  formData,
  handleChange,
  field,
  expanded,
  setCurrentExpandedField,
  maxGuests,
  generalSettings, // Added generalSettings prop
  onExceedMaxGuests, // New prop to notify parent when max is exceeded
}) => {
  const [isExpanded, setIsExpanded] = useState(expanded || false);
  const [customGuestsVisible, setCustomGuestsVisible] = useState(false);
  const guestSelectorRef = useRef(null);

  // Determine if notice should be shown
  const showNoticeForMaxGuests = generalSettings?.showNoticeForMaxGuests === 'Ja';

  // Generate preset numbers and determine if '+6' option should be shown
  let presetNumbers = [];
  let showPlusSixOption = false;

  if (showNoticeForMaxGuests) {
    // Always show preset numbers [1,2,3,4,5] and '+6' option
    presetNumbers = [1, 2, 3, 4, 5];
    showPlusSixOption = true;
  } else {
    // When showNoticeForMaxGuests is 'Nee', limit to maxGuests
    const limitedPreset = Math.min(maxGuests, 5);
    presetNumbers = Array.from({ length: limitedPreset }, (_, i) => i + 1);
    if (maxGuests > 5) {
      showPlusSixOption = true;
    }
  }

  useEffect(() => {
    // Reset guest number if exceeding maxGuests and showNoticeForMaxGuests is 'Nee'
    if (!showNoticeForMaxGuests && field) {
      const numGuests = parseInt(formData[field.id], 10);
      if (numGuests > maxGuests) {
        handleChange({
          target: { name: field.id, value: maxGuests.toString() },
        });
        if (onExceedMaxGuests) {
          onExceedMaxGuests();
        }
      }
    }
  }, [formData, field, maxGuests, handleChange, showNoticeForMaxGuests, onExceedMaxGuests]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        guestSelectorRef.current &&
        !guestSelectorRef.current.contains(event.target)
      ) {
        setIsExpanded(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setIsExpanded(expanded);
  }, [expanded]);

  useEffect(() => {
    // Log the selected number of guests whenever it changes
    setGuests(formData[field.id]);
    console.log("formData[field.id]" + formData[field.id]);
    console.log(formData[field.id]);

  }, [formData, field.id, setGuests]);

  if (!field) {
    return null;
  }

  const handleButtonClick = () => {
    setIsExpanded(!isExpanded);
    if (!isExpanded && setCurrentExpandedField) {
      setCurrentExpandedField('guest');
    }
  };

  const handleGuestSelect = (number) => {
    handleChange({
      target: { name: field.id, value: number },
    });
    setIsExpanded(false);
    setCustomGuestsVisible(false);
    if (setCurrentExpandedField) {
      setCurrentExpandedField('date');
    }
  };

  const handleCustomButtonClick = () => {
    setCustomGuestsVisible(true);
  };

  const handleInputChange = (e) => {
    let value = e.target.value.replace(/\D/g, ''); // Remove non-digit characters
    value = value ? parseInt(value, 10) : '';
    if (!showNoticeForMaxGuests && value > maxGuests) {
      value = maxGuests;
      if (onExceedMaxGuests) {
        onExceedMaxGuests();
      }
    }
    handleChange({
      target: { name: field.id, value: value.toString() },
    });
  };

  const formatDisplayGuests = () => {
    return formData[field.id]
      ? `${formData[field.id]} ${formData[field.id] === '1' ? 'Gast' : 'Gasten'}`
      : 'Selecteer aantal gasten';
  };

  const numGuests = parseInt(formData[field.id], 10) || 0;

  return (
    <div
      className="form-group guest-number-selector-container"
      ref={guestSelectorRef}
    >
      <label htmlFor={field.id} className="default-text-color">
        {field.label}
        {field.required && <span className="required">*</span>}
      </label>
      <div className="guest-number-display" onClick={handleButtonClick}>
        <span>{formatDisplayGuests()}</span>
        <span className="arrow">
          <svg
            width="12"
            height="12"
            viewBox="0 0 24 24"
            style={{
              transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
              transition: 'transform 0.2s',
            }}
          >
            <path
              d="M7 10l5 5 5-5"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
            />
          </svg>
        </span>
      </div>
      {isExpanded && (
        <div className="guest-number-selector">
          <div className="guest-number-options">
            {presetNumbers.map((number) => (
              <div
                key={number}
                className={`guest-number-option ${
                  formData[field.id] === number.toString() ? 'selected' : ''
                }`}
                onClick={() => handleGuestSelect(number.toString())}
              >
                {number}
              </div>
            ))}
            {showPlusSixOption && (
              <div
                className={`guest-number-option ${
                  numGuests > 5 ? 'selected' : ''
                }`}
                onClick={handleCustomButtonClick}
              >
                6+
              </div>
            )}
          </div>
          {customGuestsVisible && (
            <div className="guest-number-custom-input">
              <input
                type="number"
                id={field.id}
                name={field.id}
                value={formData[field.id] || ''}
                onChange={handleInputChange}
                required={field.required}
                placeholder="Aantal gasten"
                min={6}
                // Set max only if showNoticeForMaxGuests is 'Nee'
                max={!showNoticeForMaxGuests ? maxGuests : undefined}
              />
              <button
                type="button"
                className="close-custom-guest-button"
                onClick={() => setIsExpanded(false)}
              >
                Sluiten
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default GuestNumberSelector;
