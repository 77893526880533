// src/components/StepOne.jsx

import React, { useState, useEffect, useRef } from 'react';
import Title from './Title';
import DateSelector from './Fields/DateSelector';
import TimeSelector from './Fields/TimeSelector';
import GuestNumberSelector from './Fields/GuestSelector';
import MaxGuestMessage from './Notifications/index';
import MededelingNotification from './Notifications/MededelingNotification';
import useApi from '../../../Hooks/useApi'; // Adjust the path as needed
import moment from 'moment-timezone';
import { getDutchDayOfWeek } from './Utils/getDatesDutch';
import './css/notifications.css';

// Utility function to check if current selection matches any mededeling
const isMededelingActive = (selectedDate, selectedTime, mededelingen) => {
  if (!selectedDate || !mededelingen || mededelingen.length === 0) {
    return null;
  }

  const selectedDay = getDutchDayOfWeek(selectedDate);
  const selectedDateObj = new Date(selectedDate);
  let selectedTimeObj = null;

  if (selectedTime) {
    selectedTimeObj = new Date(`1970-01-01T${selectedTime}:00`); // Time only
  }

  for (let med of mededelingen) {
    // Check if selected date is within mededeling's date range
    const medStartDate = new Date(med.startDate);
    const medEndDate = new Date(med.endDate);
    if (selectedDateObj < medStartDate || selectedDateObj > medEndDate) {
      continue;
    }

    // Check if selected day is in mededeling's daysOfWeek
    if (!med.daysOfWeek.includes(selectedDay)) {
      continue;
    }

    // If mededeling has time range and selectedTime is provided
    if (med.startTime && med.endTime && selectedTimeObj) {
      const medStartTimeObj = new Date(`1970-01-01T${med.startTime}:00`);
      const medEndTimeObj = new Date(`1970-01-01T${med.endTime}:00`);
      if (selectedTimeObj >= medStartTimeObj && selectedTimeObj <= medEndTimeObj) {
        return med.mededeling;
      }
    } else {
      // If no time range or selectedTime is not provided
      return med.mededeling;
    }
  }

  return null;
};

const StepOne = ({
  title,
  timeblocks,
  formData,
  handleChange,
  resetFormDataFields,
  setCurrentStep,
  fields,
  generalNotification,
  expandedField,
  generalSettings,
  mededeling,
  restaurantData,
}) => {
  const [currentExpandedField, setCurrentExpandedField] = useState(expandedField || 'guest');
  const [guests, setGuests] = useState(1);
  const [reservations, setReservations] = useState([]);
  const api = useApi();

  // Ref to store the previous number of guests
  const previousGuestsRef = useRef(formData['guests'] || 1);

  // Use maxGasten from generalSettings, default to 10 if not available
  const maxGuests = parseInt(generalSettings?.maxGasten, 10) || 10;

  const handleNext = (e) => {
    e.preventDefault();
    setCurrentStep(2);
  };

  const timeField = fields.find((field) => field.id === 'time');
  const guestNumberField = fields.find((field) => field.id === 'guests');

  const numGuests = parseInt(formData[guestNumberField.id], 10) || 0;

  // Determine if a notice should be shown based on settings and guest number
  const showNotice =
    (generalSettings?.showNoticeForMaxGuests === 'Ja' && numGuests > maxGuests) ||
    (generalSettings?.showNoticeForMaxGuests !== 'Ja' && numGuests > maxGuests);

  // Determine the message type based on settings
  const noticeType =
    generalSettings?.showNoticeForMaxGuests === 'Ja' ? 'call' : 'limit';

  // Determine if the time has been selected
  const isTimeSelected = !!formData[timeField.id];

  // Effect to handle guest changes
  useEffect(() => {
    const previousGuests = previousGuestsRef.current;
    const currentGuests = formData[guestNumberField.id];

    if (previousGuests !== currentGuests) {
      // Reset the date and time fields when guests change
      resetFormDataFields(['date', 'time']);
      // Fetch reservations again if guests change, as availability may have changed
      fetchReservationsForMonth();
    }

    // Update the ref with the current number of guests
    previousGuestsRef.current = currentGuests;
  }, [formData, guestNumberField.id, resetFormDataFields]);

  // Function to fetch reservations for a given month
  const fetchReservationsForMonth = async (monthMoment = null) => {
    try {
      // If no month is passed, use current month
      const baseDate = monthMoment || moment().tz('Europe/Amsterdam');
      const beginDate = baseDate.clone().startOf('month').format('YYYY-MM-DD');
      const endDate = baseDate.clone().endOf('month').format('YYYY-MM-DD');
      const restaurantId = window.restaurantId;
      const endpoint = `${window.baseDomain}api/slots/${restaurantId}/${beginDate}/${endDate}`;

      const data = await api.get(endpoint, { noCache: true });
      setReservations(data || []);
    } catch (error) {
      console.error('Error fetching reservations:', error);
    }
  };

  // Fetch reservations when component mounts or guests change
  useEffect(() => {
    fetchReservationsForMonth();
  }, [guests]);

  // Callback for when the month changes in the Calendar
  const handleMonthChange = (newMonthDate) => {
    // newMonthDate is a moment object representing the start of the displayed month
    fetchReservationsForMonth(newMonthDate);
  };

  // Determine if there is an active mededeling
  const activeMededeling = isMededelingActive(
    formData.date ? formatDate(formData.date) : null,
    formData.time,
    mededeling
  );

  // Helper function to format date to 'YYYY-MM-DD'
  function formatDate(date) {
    if (!date) return null;
    const year = date.getFullYear();
    const month = (`0${date.getMonth() + 1}`).slice(-2);
    const day = (`0${date.getDate()}`).slice(-2);
    return `${year}-${month}-${day}`;
  }

  return (
    <form onSubmit={handleNext}>
      <Title title={title} subtitle="Stap 1/3" />

      {generalNotification && generalNotification.trim() !== '' && (
        <div className="general-notification">{generalNotification}</div>
      )}

      {activeMededeling && (
        <MededelingNotification message={activeMededeling} />
      )}

      <GuestNumberSelector
        setGuests={setGuests}
        formData={formData}
        handleChange={handleChange}
        field={guestNumberField}
        expanded={currentExpandedField === 'guest'}
        setCurrentExpandedField={setCurrentExpandedField}
        maxGuests={maxGuests}
        generalSettings={generalSettings}
      />

      {showNotice && (
        <MaxGuestMessage
          maxGuests={maxGuests}
          noticePhoneNumber={
            generalSettings?.showNoticeForMaxGuests === 'Ja'
              ? generalSettings.noticePhoneNumber
              : ''
          }
          type={noticeType}
        />
      )}

      {!showNotice && formData[guestNumberField.id] && (
        <DateSelector
          guests={numGuests}
          formData={formData}
          handleChange={handleChange}
          resetFormDataFields={resetFormDataFields}
          expanded={currentExpandedField === 'date'}
          setCurrentExpandedField={setCurrentExpandedField}
          timeblocks={timeblocks}
          restaurantData={restaurantData}
          reservations={reservations}
          onMonthChange={handleMonthChange} // Pass down to handle month changes
        />
      )}

      {!showNotice && formData.date && (
        <TimeSelector
          guests={numGuests}
          formData={formData}
          handleChange={handleChange}
          field={timeField}
          selectedDate={formData.date}
          expanded={currentExpandedField === 'time'}
          setCurrentExpandedField={setCurrentExpandedField}
          timeblocks={timeblocks}
          restaurantData={restaurantData}
          reservations={reservations}
        />
      )}

      {!showNotice && isTimeSelected && (
        <button type="submit" className="submit-button">
          Volgende
        </button>
      )}
    </form>
  );
};

export default StepOne;
