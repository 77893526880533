// src/components/NewReservation/ReservationFullScreen.jsx

import React from 'react';
import './css/fullScreen.css';

const ReservationFullScreen = ({ theme, formContent }) => {
  return (
    <>
      <div className="top-image-section">
        <img
          src={`https://static.reservaties.net/themes/${theme?.id}.webp`}
          alt={theme?.title}
          className="top-image"
        />
      </div>

      <div className="form-section">{formContent}</div>
    </>
  );
};

export default ReservationFullScreen;
